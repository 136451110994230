import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import DefaultLayout from "@/views/layouts/Default/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/candidate/:url(.+?)",
    component: () => import("@/views/CandidateProfile/index.vue"),
    meta: {
      layout: DefaultLayout,
    },
  },
  {
    path: "/company/:url(.+?)",
    component: () => import("@/views/CompanyPage/index.vue"),
    meta: {
      layout: DefaultLayout,
    },
  },
  {
    path: "/opportunity/:url(.+?)",
    component: () => import("@/views/VacancyPage/index.vue"),
    meta: {
      layout: DefaultLayout,
    },
  },
  {
    path: "/recruiter/:url(.+?)",
    component: () => import("@/views/RecruiterPage/index.vue"),
    meta: {
      layout: DefaultLayout,
    },
  },
  {
    path: "*",
    beforeEnter() {
      window.location.href = process.env.VUE_APP_MAIN_SITE_URL;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const firstEnter = from.fullPath === "/";

  if (to.meta) {
    to.meta.firstEnter = firstEnter;
  } else {
    to.meta = {
      firstEnter,
    };
  }

  next();
});

export default router;
