import { EnumItemModel } from "@/models/common";

enum EVacancyType {
  FullTime = 0,
  Contract = 1,
  PartTime = 2,
  Internship = 3,
  Volunteering = 4,
  Academic = 5,
  FirstShift = 6,
  SecondShift = 7,
  ThirdShift = 8,
  TemporaryOpportunity = 9,
  TempToPermanentOpportunity = 10,
  DirectHireOpportunity = 11,
  TravelRequired = 12,
}

const EVacancyTypeItems: ReadonlyArray<EnumItemModel<EVacancyType>> = [
  { text: "Full-Time", value: EVacancyType.FullTime },
  { text: "Contract", value: EVacancyType.Contract },
  { text: "Part-time", value: EVacancyType.PartTime },
  { text: "Internship", value: EVacancyType.Internship },
  { text: "Volunteering", value: EVacancyType.Volunteering },
  { text: "Academic", value: EVacancyType.Academic },
  { text: "1st Shift", value: EVacancyType.FirstShift },
  { text: "2st Shift", value: EVacancyType.SecondShift },
  { text: "3st Shift", value: EVacancyType.ThirdShift },
  { text: "Temporary", value: EVacancyType.TemporaryOpportunity },
  {
    text: "Temp-to-Permanent",
    value: EVacancyType.TempToPermanentOpportunity,
  },
  {
    text: "Direct Hire",
    value: EVacancyType.DirectHireOpportunity,
  },
  { text: "Travel required", value: EVacancyType.TravelRequired },
];

export { EVacancyType, EVacancyTypeItems };
