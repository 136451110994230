

































import { Component, Prop, Vue } from "vue-property-decorator";
import { DasboardLinkModel } from "@/models/Account";

@Component({
  name: "UserMenuContent",
  components: {},
})
export default class UserMenuContent extends Vue {
  @Prop({ required: true, default: [] })
  public readonly links!: DasboardLinkModel[];

  get items() {
    return this.links.map((x) => ({
      title: x.name,
      icon: this.getItemIcon(x.name),
      link: x.url,
    }));
  }

  onLogoutClick() {
    window.location.href = `${process.env.VUE_APP_MAIN_SITE_URL}/Iam/Account/LogOff`;
  }

  getItemIcon(value: string) {
    switch (value.toLowerCase()) {
      case "home":
        return "mdi-gauge-full";
      case "opportunities":
        return "mdi-briefcase";
      case "messages":
        return "mdi-email";
      case "interviews":
        return "mdi-forum";
      case "favorites":
        return "mdi-heart";
      case "media":
        return "mdi-play-box";
      case "photo album":
        return "mdi-image";
      case "profile details":
        return "mdi-account-tie";
      case "divisions":
        return "mdi-account-multiple";
      case "user management":
        return "mdi-cog";
      default:
        return "";
    }
  }
}
