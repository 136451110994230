enum EMediaType {
  Image,
  Text,
  Presentation,
  Video,
  Unknown,
  JobVideo,
  SampleVideo,
  SampleImage,
  SampleText,
  MessageVideo,
  MessageText,
  MessagePresentation,
}

export default EMediaType;
