



















import { Component, Prop, Vue } from "vue-property-decorator";
import BaseLink from "@/components/common/BaseLink.vue";
import { IFooterLink } from "@/store/modules/headerFooterInfo";

@Component({
  name: "LinksList",
  components: { BaseLink },
})
export default class LinksList extends Vue {
  @Prop({ required: true, type: Object })
  readonly links!: IFooterLink;
}
