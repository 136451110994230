






























































import { Component, Vue } from "vue-property-decorator";
import UserMenu from "./components/UserMenu/index.vue";
import { namespace } from "vuex-class";
import { ProfileModel } from "@/models/Account";
import { AccountService } from "@/services/Security";
import UserMenuButton from "@/views/layouts/Default/components/AppHeader/components/UserMenu/UserMenuButton.vue";
import UserMenuMobileDialog from "@/views/layouts/Default/components/AppHeader/components/UserMenu/UserMenuMobileDialog.vue";
import UserMenuContent from "@/views/layouts/Default/components/AppHeader/components/UserMenu/UserMenuContent.vue";

const HeaderFooterInfo = namespace("headerFooterInfo");

@Component({
  name: "AppHeader",
  components: {
    UserMenuContent,
    UserMenuMobileDialog,
    UserMenuButton,
    UserMenu,
  },
})
export default class extends Vue {
  @HeaderFooterInfo.Getter("getLogoImageUrl") logoImageUrl!: string;

  private profile: ProfileModel | null = null;

  private loading = false;

  private userMenuIsShowed = false;

  private homeUrl = process.env.VUE_APP_MAIN_SITE_URL;

  async mounted() {
    try {
      this.loading = true;
      this.profile = await AccountService.getProfile();
    } catch (e) {
      console.log("Header fetching error: ", e);
    } finally {
      this.loading = false;
    }
  }

  toggleUserMenuIsShowed(): void {
    this.userMenuIsShowed = !this.userMenuIsShowed;
  }

  hideUserMenuIsShowed(): void {
    this.userMenuIsShowed = false;
  }
}
