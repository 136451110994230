
















import { Component, Vue } from "vue-property-decorator";
import AppHeader from "./components/AppHeader/index.vue";
import AppFooter from "@/views/layouts/Default/components/AppFooter/index.vue";

@Component({
  name: "DefaultLayout",
  components: {
    AppFooter,
    AppHeader,
  },
})
export default class extends Vue {}
