import { TOKEN_COOKIE_NAME } from "@/constants/LocalStorageConstants";
import cookie from "cookie";

export const getAuthToken = () => {
  const cookiesData = cookie.parse(document.cookie);
  return cookiesData[TOKEN_COOKIE_NAME];
};

// export const clearAuthData = () => {
//   localStorage.removeItem(AUTH_TOKEN_KEY_NAME);
//   localStorage.removeItem(REFRESH_TOKEN_KEY_NAME);
//   localStorage.removeItem(ROLES_KEY_NAME);
// };
