















































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { FOOTER_MENU } from "@/constants/HeaderFooterInfo";
import LinksList from "./components/LinksList.vue";
import BaseLink from "@/components/common/BaseLink.vue";
import {
  IConcatContent,
  IFooterLink,
  ISocialLink,
} from "@/store/modules/headerFooterInfo";
import ContactInfoList from "@/views/layouts/Default/components/AppFooter/components/ContactInfoList.vue";
import { getMapLink } from "@/utils/generateMapLink";

const HeaderFooterInfo = namespace("headerFooterInfo");

type ICols = {
  md: number[];
  sm: number[];
};

@Component({
  name: "AppFooter",
  components: { ContactInfoList, BaseLink, LinksList },
})
export default class AppFooter extends Vue {
  @HeaderFooterInfo.Getter("getLogoImageUrl") logoImageUrl!: string;

  @HeaderFooterInfo.Getter("getSocialLinks") socialLinks!: ISocialLink[];

  @HeaderFooterInfo.Getter("getContactAddress") contactAddress!:
    | string
    | IConcatContent[];

  linksCategories = FOOTER_MENU;

  get contactAddressLinks(): IFooterLink {
    return {
      title: "Contacts",
      children: [
        {
          url: !Array.isArray(this.contactAddress)
            ? this.mapLink(this.contactAddress)
            : "",
          isExternal: true,
          title: !Array.isArray(this.contactAddress)
            ? (this.contactAddress as string)
            : "",
        },
      ],
    };
  }

  get contactInfoLinks(): IFooterLink {
    return {
      title: "Contacts",
      children: Array.isArray(this.contactAddress)
        ? this.contactAddress.map((item) => ({
            url: item.value,
            isExternal: true,
            title: item.value,
            type: item.type,
          }))
        : [],
    };
  }

  mapLink(path: string): string {
    return getMapLink(path);
  }
}
