import Vuex from "vuex";

import companyInfo from "./modules/companyInfo";
import headerFooterInfo from "./modules/headerFooterInfo";

const store = new Vuex.Store({
  state: {},
  modules: {
    companyInfo,
    headerFooterInfo,
  },
});

export default store;
