
import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component({
  name: "BaseLink",
})
export default class BaseLink extends Vue {
  @Prop({ required: false, type: [Object, String], default: "#" })
  readonly to!: RawLocation;

  @Prop({ required: false, type: Boolean, default: false })
  readonly isExternal!: boolean;
}
