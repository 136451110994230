export function getMapLink(path: string): string {
  if (!path) {
    return "";
  }

  const pathEncode: string = encodeURIComponent(path);

  const { platform } = navigator;

  const isIOSDevice: boolean =
    platform.indexOf("iPhone") != -1 ||
    platform.indexOf("iPad") != -1 ||
    platform.indexOf("iPod") != -1;

  if (isIOSDevice) {
    return `maps://maps.google.com/maps/search/${pathEncode}`;
  } else {
    return `https://maps.google.com/maps/search/${pathEncode}`;
  }
}
