import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  DEFAULT_CONTACT_ADDRESS,
  DEFAULT_LOGO_IMAGE_URL,
  DEFAULT_SOCIAL_LINKS,
} from "@/constants/HeaderFooterInfo";

export type IFooterLinkChild = {
  title?: string;
  url?: string;
  isExternal?: boolean;
};

export type IFooterLink = {
  title: string;
  children: IConcatContent[];
};

export type ISocialLink = {
  url: string;
  iconImageUrl: string;
};

export interface IConcatContent extends IFooterLinkChild {
  type?: number;
  value?: string;
}

export type IUpdateHeaderFooterArgs = {
  logoImageUrl?: string;
  contactAddress?: IConcatContent[] | string;
  socialLinks?: ISocialLink[];
};

export type IUpdateHeaderFooterData = (args?: IUpdateHeaderFooterArgs) => void;

@Module({ namespaced: true })
class HeaderFooterInfo extends VuexModule {
  public logoImageUrl = DEFAULT_LOGO_IMAGE_URL;
  public contactAddress: string | IConcatContent[] = DEFAULT_CONTACT_ADDRESS;
  public socialLinks: ISocialLink[] = DEFAULT_SOCIAL_LINKS;

  get getLogoImageUrl() {
    return this.logoImageUrl;
  }

  get getContactAddress() {
    return this.contactAddress;
  }

  get getSocialLinks() {
    return this.socialLinks;
  }

  @Mutation
  public setLogoImageUrl(logoImageUrl: string) {
    this.logoImageUrl = logoImageUrl;
  }

  @Mutation
  public setContactAddress(contactAddress: IConcatContent[] | string) {
    this.contactAddress = contactAddress;
  }

  @Mutation
  public setSocialLinks(socialLinks: ISocialLink[]) {
    this.socialLinks = socialLinks;
  }

  @Action
  public updateData(args?: IUpdateHeaderFooterArgs) {
    this.context.commit(
      "setLogoImageUrl",
      args?.logoImageUrl || DEFAULT_LOGO_IMAGE_URL
    );

    this.context.commit(
      "setContactAddress",
      args?.contactAddress || DEFAULT_CONTACT_ADDRESS
    );

    this.context.commit(
      "setSocialLinks",
      args?.socialLinks || DEFAULT_SOCIAL_LINKS
    );
  }
}

export default HeaderFooterInfo;
