import axios from "axios";
import { getAuthToken } from "./cookieStorage";
// import {
//   getAuthToken,
//   getRefreshToken,
//   clearAuthData,
//   setNewAuthToken
// } from "@shared/utils/localStorage";
// import { formQueryString } from "@shared/utils/fromQueryString";
// import environment from "@shared/environments/environment";

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const sourcesList: { [key: string]: any } = {};

request.interceptors.request.use(
  (config) => {
    const token = getAuthToken();

    // if (config.method === "get" && config.params) {
    //   config.url += `?${formQueryString(config.params)}`;
    //   config.params = undefined;
    // }

    if (config.headers && token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.__CANCEL__) return null;

    const status = error.response ? error.response.status : null;
    if (status === 401) {
      // clearAuthData();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const getCancelToken = (key: string) => {
  const source = axios.CancelToken.source();

  if (sourcesList[key]) {
    sourcesList[key].cancel();
  }
  sourcesList[key] = source;
  return source.token;
};

export default request;

export { request, getCancelToken };
