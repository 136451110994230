import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class CompanyInfo extends VuexModule {
  public name = "";

  @Mutation
  public setName(name: string) {
    this.name = name;
  }

  @Action({ commit: "setName" })
  public updateName(newName: string) {
    return newName;
  }

  get getName() {
    return this.name;
  }
}
export default CompanyInfo;
