import { IFooterLink, ISocialLink } from "@/store/modules/headerFooterInfo";

const DEFAULT_LOGO_IMAGE_URL = "/img/logo/recruit-logo.png";

const DEFAULT_CONTACT_ADDRESS = "";

const DEFAULT_SOCIAL_LINKS: ISocialLink[] = [
  {
    url: "https://www.twitter.com/recruit",
    iconImageUrl: "/img/icons/twitter.svg",
  },
  {
    url: "https://www.facebook.com/recruitdotcom",
    iconImageUrl: "/img/icons/facebook.svg",
  },
  {
    url: "https://www.instagram.com/recruitdotcom",
    iconImageUrl: "/img/icons/instagram.svg",
  },
];

const FOOTER_MENU: IFooterLink[] = [
  {
    title: "LEARN",
    children: [
      {
        title: "FAQ",
        url: "https://recruit.com/Iam/Home/FAQ",
        isExternal: true,
      },
      {
        title: "Blog",
        url: "https://recruit.com/blog",
        isExternal: true,
      },
      {
        title: "Contact us",
        url: "https://recruit.com/Iam/Home/Contact",
        isExternal: true,
      },
    ],
  },
  {
    title: "LEGAL",
    children: [
      {
        title: "Privacy Policy",
        url: "https://recruit.com/Iam/Home/PrivacyPolicy",
        isExternal: true,
      },
      {
        title: "Terms of Use",
        url: "https://recruit.com/Iam/Home/TermsOfUse",
        isExternal: true,
      },
    ],
  },
];

export {
  DEFAULT_LOGO_IMAGE_URL,
  DEFAULT_CONTACT_ADDRESS,
  DEFAULT_SOCIAL_LINKS,
  FOOTER_MENU,
};
