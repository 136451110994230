import request from "@/utils/request";
import { ProfileModel } from "@/models/Account";

class AccountService {
  async getProfile() {
    return await request.get<ProfileModel>(`/security/account/profile`);
  }
}

export default new AccountService();
