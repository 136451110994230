
















import { Component, VModel, Vue } from "vue-property-decorator";

@Component({
  name: "UserMenu",
  components: {},
})
export default class extends Vue {
  @VModel({ required: true, type: Boolean, default: false })
  private isShowed!: boolean;
}
