



















import { Component, Prop, Vue } from "vue-property-decorator";
import { ProfileModel } from "@/models/Account";

@Component({
  name: "UserMenuButton",
  components: {},
})
export default class UserMenuButton extends Vue {
  @Prop({ required: true, default: () => ({}) })
  public readonly profile!: ProfileModel;

  get title() {
    return `${this.profile.firstName} ${this.profile.lastName}`;
  }

  get avatar() {
    return this.profile.avatar
      ? this.profile.avatar.resizedUrl
      : require("@/assets/no-avatar.svg");
  }
}
