
























































import { Component, Prop, Vue } from "vue-property-decorator";
import BaseLink from "@/components/common/BaseLink.vue";
import { IFooterLink } from "@/store/modules/headerFooterInfo";
import { telephoneNumber } from "@/utils/generateTelephoneLink";
import { getMapLink } from "@/utils/generateMapLink";
import { ECandidatePageMenuAction } from "@/models/enums";

@Component({
  name: "ContactInfoList",
  components: { BaseLink },
})
export default class ContactInfoList extends Vue {
  @Prop({ required: true, type: Object })
  readonly links!: IFooterLink;

  ECandidatePageMenuAction = ECandidatePageMenuAction;

  phoneNumber(phone: string): string {
    return telephoneNumber(phone);
  }

  mapLink(path: string): string {
    return getMapLink(path);
  }
}
